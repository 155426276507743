<section class="home container">
  <h4 class="home__title">See home listings from all top sites in one place</h4>
  <div class="home__images-box">
    <img class="home__zillow" ngSrc="assets/images/zillow.png" alt="zillow" width="92" height="22">
    <img class="home__apartments" ngSrc="assets/images/apartments.png" alt="apartments" width="204" height="36">
    <img class="home__renthop2" ngSrc="assets/images/renthop2.png" alt="renthop" width="98" height="32">
    <img class="home__renthop" ngSrc="assets/images/renthop.png" alt="renthop" width="102" height="36">
    <img class="home__street-easy" ngSrc="assets/images/street-easy.png" alt="street-easy" width="144" height="32">
    <img class="home__trulia" ngSrc="assets/images/trulia.png" alt="trulia" width="76" height="32">
  </div>

  <div class="home__main" [class.expand]="isOpen" [formGroup]="searchForm">
    <div class="home__field">
      <ninja-autocomplete
        label="Location"
        formControlName="location"
        placeholder="City, Neighborhood, ZIP"
        (onKeyup)="onNeighborhood($event)"
        [loading]="neighborhoodsLoading"
        [options]="neighborhoods">
      </ninja-autocomplete>
    </div>
    <div class="home__price-range home__field">
      <ninja-select formControlName="price_from" label="Price" [data]="minPrices"
                    placeholder="Min"></ninja-select>
      <ninja-select formControlName="price_to" [data]="maxPrices" placeholder="Max"></ninja-select>
    </div>
    <div class="home__field">
      <ninja-select-button formControlName="rent_or_sale" [data]="rentOrBuy" size="bigger" [grid]="2"></ninja-select-button>
    </div>
    @if (isOpen) {
      <div class="home__field">
        <ninja-select-button formControlName="bedrooms" label="Bedrooms" [data]="bedrooms" [grid]="6"></ninja-select-button>
      </div>
      <div class="home__field">
        <ninja-select-button formControlName="bathrooms" label="Bathrooms" [data]="bathrooms" [grid]="6"></ninja-select-button>
      </div>
      <div class="home__field">
        <ninja-toggle-switch formControlName="pet" label="Pets allowed"></ninja-toggle-switch>
      </div>
      <div class="home__field">
        <ninja-select formControlName="property_type" multiple label="Property type" placeholder="All Home types"
                      [data]="propertyTypes"></ninja-select>
      </div>
      <div class="home__square-feet home__field">
        <ninja-select formControlName="sqft_from" label="Square feet" [data]="minSquareFeet"
                      placeholder="Min"></ninja-select>
        <ninja-select formControlName="sqft_to" [data]="maxSquareFeet" placeholder="Max"></ninja-select>
      </div>
    }
    <div class="home__field">
      <ninja-button styles="w-full lg:w-192 py-16" (click)="submit()">
        Search
      </ninja-button>
    </div>
    <div class="home__more-container">
      <span class="home__more" (click)="expandToggle()">
        {{ isOpen ? 'Default view' : 'More filters' }}
        <i class="material-icons">{{ isOpen ? 'expand_less' : 'expand_more' }}</i>
      </span>
    </div>
  </div>
</section>
