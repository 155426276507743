<header class="header">
  <div class="header__container">
    <div>
      <img class="header__logo" src="assets/images/logo.png" alt="" [routerLink]="['']">
    </div>
<!--    <div class="header__login-box">-->
<!--      <i class="material-icons text-secondary500">person</i>-->
<!--      <p class="header__login-text">Login/Sign up</p>-->
<!--    </div>-->
    <div class="header__menu-box" (click)="openSidenav()">
      <i class="material-icons">menu</i>
    </div>
  </div>
</header>
@if (isOpenSidenav) {
  <ninja-sidenav (close)="closeSidenav()"></ninja-sidenav>
}
