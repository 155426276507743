import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';
import { SidenavComponent } from '@layout/sidenav/sidenav.component';

@Component({
  selector: 'ninja-header',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, SidenavComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  isOpenSidenav: boolean = false;

  openSidenav() {
    this.isOpenSidenav = true;
  }

  closeSidenav() {
    this.isOpenSidenav = false;
  }
}
