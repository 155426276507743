import { Component, inject, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { TextboxComponent } from '@component/textbox/textbox.component';
import { SelectComponent } from '@component/select/select.component';
import { ButtonComponent } from '@component/button/button.component';
import { SelectButtonComponent } from '@component/select-button/select-button.component';
import { ToggleSwitchComponent } from '@component/toggle-switch/toggle-switch.component';
import { ResultsService } from '@results/shared/services/results.service';
import { AutocompleteComponent } from '@component/autocomplete/autocomplete.component';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'ninja-home',
  standalone: true,
  imports: [
    CommonModule,
    TextboxComponent,
    SelectComponent,
    ReactiveFormsModule,
    ButtonComponent,
    SelectButtonComponent,
    ToggleSwitchComponent,
    NgOptimizedImage,
    AutocompleteComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  searchForm: FormGroup;
  isOpen: boolean = false;
  minPrices: { value: string; label: string }[] = [];
  maxPrices: { value: string; label: string }[] = [];
  minSquareFeet: { value: string; label: string }[] = [];
  maxSquareFeet: { value: string; label: string }[] = [];
  rentOrBuy: { value: string; key: string }[] = [
    {value: 'Rent', key: 'RENT'},
    {value: 'Buy', key: 'SALE'}
  ];
  bathrooms: { value: string; key: string }[] = [
    {value: 'any', key: ''},
    {value: '1+', key: '1'},
    {value: '2+', key: '2'},
    {value: '3+', key: '3'},
    {value: '4+', key: '4'},
    {value: '5+', key: '5'},
  ];
  bedrooms: { value: string; key: string }[] = [
    {value: 'any', key: ''},
    {value: '1+', key: '1'},
    {value: '2+', key: '2'},
    {value: '3+', key: '3'},
    {value: '4+', key: '4'},
    {value: '5+', key: '5'},
  ];
  propertyTypes: { value: string; label: string }[] = [];
  neighborhoods: { key: string; value: string }[] = [];
  neighborhoodsLoading: boolean = false;

  private readonly _fb = inject(FormBuilder);
  private readonly _router = inject(Router);
  private readonly _resultsService = inject(ResultsService);
  private readonly _activatedRoute = inject(ActivatedRoute);

  constructor() {
    this.searchForm = this._fb.group({
      location: [''],
      price_from: [''],
      price_to: [''],
      rent_or_sale: ['RENT'],
      bedrooms: [''],
      bathrooms: [''],
      pet: [false],
      property_type: [''],
      sqft_from: [''],
      sqft_to: [''],
    });
  }

  ngOnInit() {
    this.getConfig();
    this.searchForm.get('rent_or_sale')?.valueChanges.subscribe(value => {
      this.searchForm.get('price_from')?.patchValue('');
      this.searchForm.get('price_to')?.patchValue('');
      this._resultsService.getFilterConfig().subscribe((res) => {
        const priceRange = res.price_ranges[value];
        this.minPrices = priceRange.min_price.map((minPrice) => ({value: minPrice.value, label: minPrice.label}));
        this.maxPrices = priceRange.max_price.map((maxPrice) => ({value: maxPrice.value, label: maxPrice.label}));
        this.searchForm.get('price_from')?.valueChanges.subscribe(value => {
          this.maxPrices = priceRange.max_price.filter((maxPrice) => Number(maxPrice.value) >= Number(value)).map((maxPrice) => ({
            value: maxPrice.value,
            label: maxPrice.label
          }));
        });
      });
    });
  }

  getConfig() {
    this._resultsService.getFilterConfig().subscribe((res) => {
      const priceRange = res.price_ranges[this.searchForm.get('rent_or_sale')?.value];
      const areaRange = res.total_structure_area;
      const propertyTypes = res.property_types;
      this.minPrices = priceRange.min_price.map((minPrice) => ({value: minPrice.value, label: minPrice.label}));
      this.maxPrices = priceRange.max_price.map((maxPrice) => ({value: maxPrice.value, label: maxPrice.label}));
      this.searchForm.get('price_from')?.valueChanges.subscribe(value => {
        this.searchForm.get('price_to')?.patchValue('');
        this.maxPrices = priceRange.max_price.filter((maxPrice) => Number(maxPrice.value) >= Number(value)).map((maxPrice) => ({
          value: maxPrice.value,
          label: maxPrice.label
        }));
      });
      areaRange.min_square_feet.forEach(value => {
        this.minSquareFeet.push({value: value.value, label: `${value.label} sqft`});
      });
      areaRange.max_square_feet.forEach(value => {
        this.maxSquareFeet.push({value: value.value, label: `${value.label} sqft`});
      });
      this.searchForm.get('sqft_from')?.valueChanges.subscribe(value => {
        this.searchForm.get('sqft_to')?.patchValue('');
        this.maxSquareFeet = areaRange.max_square_feet.filter((maxSqft) => Number(maxSqft.value) >= Number(value)).map((maxPrice) => ({
          value: maxPrice.value,
          label: `${maxPrice.label} sqft`
        }));
      });
      this.propertyTypes = propertyTypes.map((property: { name: string; value: string }) => ({
        label: property.name,
        value: property.value
      }));
    });
  }

  expandToggle() {
    this.isOpen = !this.isOpen;
  }

  onNeighborhood(value: string) {
    if (value) {
      this.neighborhoodsLoading = true;
      this._resultsService.searchNeighborhood(value).pipe(debounceTime(500)).subscribe((res) => {
        this.neighborhoods = res.results.map((result) => ({value: result.display, key: String(result.id)}));
        this.neighborhoodsLoading = false;
      });
    }
  }

  submit() {
    const {pet, location, ...rest} = this.searchForm.value;
    let params = {
      location: location?.key || 2,
      provider: '',
      is_exact_bedrooms: false,
      is_exact_bathrooms: false,
      cat: pet ? pet : '',
      dog: pet ? pet : '',
      top_left: '',
      bottom_right: '',
      center: '',
      sort_by: 1,
      ...rest,
    };
    this._router.navigate(['results'], {queryParams: params, relativeTo: this._activatedRoute, queryParamsHandling: 'merge'});
  }
}
