import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '@layout/header/header.component';
import { FooterComponent } from '@layout/footer/footer.component';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, map, switchMap } from 'rxjs';

@Component({
  selector: 'ninja-layout',
  standalone: true,
  imports: [CommonModule, HeaderComponent, FooterComponent, RouterOutlet],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {
  justHeader: boolean = false;

  constructor(private _router: Router,
              private _activatedRoute: ActivatedRoute) {
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route = this._activatedRoute;
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      switchMap(route => route.data),
      map(data => data['justHeader'])
    ).subscribe(justHeader => {
      this.justHeader = justHeader ? justHeader : false;
    });
  }
}
