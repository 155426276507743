import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '@env/environment.development';

export const BaseInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  let copyReq = req.clone();
  let url = req.url;

  if (!req.url.startsWith('.')) {
    const prefixReq: 'main' = url.split('/')[0] as 'main';
    const serverUrl = environment.baseUrl[prefixReq];
    copyReq = req.clone({
      url: `${serverUrl}${url.replace(prefixReq, '')}`,
    });
  }

  return next(copyReq).pipe(
    catchError((err: any) => {
      console.log(err);
      return throwError(() => err);
    })
  );
};
