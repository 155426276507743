import { Routes } from '@angular/router';
import { HomeComponent } from '@home/home.component';
import { LayoutComponent } from '@layout/layout.component';

export const routes: Routes = [
  {
    path: '', component: LayoutComponent, children: [
      {path: '', component: HomeComponent, title: 'Search'},
      {path: 'about', loadChildren: () => import('@about/about-us.routes').then(m => m.AboutUsRoutes)},
      {path: 'contact', loadChildren: () => import('@contact/contact-us.routes').then(m => m.ContactUsRoutes)},
      {path: 'faq', loadChildren: () => import('@faq/faq.routes').then(m => m.FaqRoutes)},
      {
        path: 'results',
        loadChildren: () => import('@results/results.routes').then(m => m.ResultsRoutes),
        data: {justHeader: true}
      },
    ],
  },
];
