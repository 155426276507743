import { Component, inject, output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'ninja-sidenav',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss'
})
export class SidenavComponent {
  close = output();
  private _router = inject(Router);

  closeSidenav() {
    this.close.emit();
  }

  changeRoute(route: string) {
    this._router.navigate([route]).then(() => {
      this.close.emit();
    });
  }
}
