<aside class="sidenav">
  <i class="material-icons sidenav__close" (click)="closeSidenav()">close</i>
<!--  <div class="sidenav__login">-->
<!--    <i class="material-icons text-secondary500">person</i>-->
<!--    <span>Login/Sign up</span>-->
<!--  </div>-->
<!--  <hr>-->
  <ul class="sidenav__ul">
    <li class="sidenav__li" (click)="changeRoute('/')">Home</li>
    <li class="sidenav__li" (click)="changeRoute('about')">About us</li>
    <li class="sidenav__li" (click)="changeRoute('contact')">Contact us</li>
    <li class="sidenav__li" (click)="changeRoute('faq')">FAQ</li>
    <li class="sidenav__li">Privacy policy</li>
    <li class="sidenav__li">Terms of services</li>
  </ul>
</aside>
